import { PaginatedResponse } from '@tsp-ui/core';

import {
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    CommitmentType,
    DocumentationType,
    LoanLimitType,
    LoanPurpose,
    LoanType,
    LockPeriod,
    NumUnits,
    OccupancyType,
    PaginatedGetParams,
    PropertyType,
    SpecialtyProgram,
    State,
    UploadStatus
} from '..';
import { apiUtils, getAccountBaseUrl } from '../api-utils';


export function getPricingResults(
    clientId: string,
    customerId?: string,
    params?: PaginatedGetParams
): Promise<PaginatedResponse<LoanPricingResult>> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing`, params);
}

export function getPricingResultDetail(
    clientId: string, loanID: string, customerId?: string
): Promise<LoanPricingResultDetail> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/${loanID}`);
}

export function priceLoan(clientId: string, body: ManualLoanEntry, customerId: string): Promise<LoanPricingResult> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/pricing/price`, body);
}

export function repriceLoan(clientId: string, loanID: string, customerId?: string): Promise<LoanPricingResult> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/pricing/reprice`, { loanID });
}

export function getRateSheets(
    clientId: string, customerId?: string, params?: PaginatedGetParams
): Promise<PaginatedResponse<RateSheet>> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/rate-sheet`, params);
}

export function getCurrentRateSheet(clientId: string, customerId?: string): Promise<RateSheet | undefined> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/rate-sheet/current`);
}

export function getRateSheetById(
    clientId: string, customerId?: string, rateSheetId?: string
): Promise<RateSheet> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/rate-sheet/${rateSheetId}`);
}

export function createRateSheet(clientId: string, request: CreateRateSheetRequest): Promise<RateSheet> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(request.rateSheet));
    request.files.forEach(file => {
        formData.append('files', file);
    });
    return apiUtils.post(`/client/${clientId}/pricing/rate-sheet`, formData, { useAutoContentType: true });
}

export function getCurrentNoteRates(clientId: string, customerId?: string): Promise<number[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/ratesheet/current/rates`);
}

export function getNoteRatePricingResults(
    clientId: string, loanId: string, customerId?: string, params?: NoteRatePricingResultParams
): Promise<LoanPricingResultDetail> {
    return apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/pricing/${loanId}/price`, params);
}

export interface LoanPricingResult {
    id: string;
    loanId: string;
    borrowerName: string;
    expirationDate: string; // TODO post-demo move this to the product result
    interestRate: number;
    loanAmount: number;
    loanNumber: string;
    pricedDate: string;
    rateSheetId: string;
    customerId: string;
}

export interface LoanPricingResultDetail {
    defaultLockPeriod: LockPeriod;
    products: PricingResultProduct[];
    ineligibleProducts?: PricingResultIneligibleProduct[];
}

export interface PricingResultProduct {
    productId: string;
    basePrice?: number;
    commitmentType: CommitmentType;
    description: string;
    docUrl: string;
    finalPrice: number;
    investor: string;
    lockPeriod: LockPeriod;
    margin?: number;
    llpas?: number;
    noteRate?: number;
    adjustments: PricingResultAdjustment[];
}

export interface PricingResultIneligibleProduct {
    productId: string;
    description: string;
    ineligibleReason: string;
}

export interface PricingResultAdjustment {
    id: string;
    amount: number;
    capTotal: number;
    description: string;
    gos: boolean;
    isCap: boolean;
    srp: boolean;
}

export interface PricingUploadBatchRequest {
    rateSheetId: string;
    submittedBy: string;
    lockPeriod: number;
}

export interface PricingUploadBatch {
    batchId: string;
    createdDate: string;
    customerId: string;
    commitmentId?: string; // TODO PREDEV-2455
    rateSheetId: string;
    fileCount: number;
    submittedBy: string;
    status: UploadStatus;
    files: FileUpload[];
}

export interface BatchPricingResults {
    batchId: string;
    results: LoanPricingResult[];
}

export interface FileUpload {
    fileId: string;
    fileName: string;
    loanCount: number;
    passedValidation: boolean;
    errors: FileUploadError[];
    status: UploadStatus;
}

export interface FileUploadError {
    prospectiveLoanFileId: string;
    fileRowNumber: string;
    prospectiveLoanDisplayNumber: string;
    errorDetails: string[];
}

export interface ManualLoanEntry {
    // borrower step
    borrowers: Borrower[];
    // property step
    occupancyType: OccupancyType;
    loanPurpose: LoanPurpose;
    propertyType: PropertyType;
    proposedHousingPayment: number;
    otherPayments: number;
    appraisedValue: number;
    salesPrice?: number;
    propertyUnits: NumUnits;
    propertyCity: string;
    propertyState: State;
    propertyZipCode: string;
    propertyCounty: string;
    // loan step
    customerLoanNumber: string;
    universalLoanIdentifier?: string;
    loanAmount: number;
    baseLoanAmount?: number;
    loanType: LoanType;
    loanLimitType: LoanLimitType;
    loanTerm: number;
    interestRate: number;
    lockPeriod: LockPeriod;
    loanFICO: number;
    qualifyingTotalIncome: number;
    dti: number;
    amorType: AmortizationType;
    armMargin?: number;
    armInitialCap?: number;
    armSubsequentCaps?: number;
    armLifeCap?: number;
    escrowReserves: number;
    escrowsFlag: boolean;
    interestOnlyFlag: boolean;
    // additional details step
    productCode?: string;
    specialtyProgram?: SpecialtyProgram;
    documentationType?: DocumentationType;
    subordinatedBalance?: number;
    cashOutAmount?: number;
    limitedLiabilityCorp?: string;
    commitmentType?: CommitmentType;
    commitmentIdentifier?: string;
    mortgageInsFlag?: boolean;
    mortgageInsCompany?: string;
    underwriteFlag?: boolean;
    automatedUwSystem?: AutomatedUwSystem;
    automatedUwRecommendation?: AutomatedUwRecommendation;
}

export interface Borrower {
    id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    fico?: number;
    ssn?: string;
    email?: string;
    primaryWageEarner?: boolean;
    firstTimeHomeBuyer?: boolean;
}

export interface RateSheet {
    id: string;
    creatorUserId: string;
    effectiveDate: string;
    expirationDate: string | null;
    isCurrent: boolean;
    excelUrl?: string;
    pdfUrl?: string;
}

export interface CreateRateSheetRequest {
    rateSheet: Omit<RateSheet, 'id'>;
    files: File[];
}

export interface NoteRatePricingResultParams {
    noteRate?: number;
    productId?: string;
}
