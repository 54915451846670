import { PricingFieldName } from '@api';
import {
    FormLabel, Button as MuiButton, Paper
} from '@mui/material';
import {
    Button, Switch, TextField, isAtLeast, isAtMost, isEmail
} from '@tsp-ui/core/components';
import { useFieldArray } from 'react-hook-form';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import styles from './BorrowersStep.module.scss';
import ManualLoanEntryStep  from './ManualLoanEntryStep';


const borrowerArrayName = 'borrowers';

interface BorrowerStepProps {
    isFieldEnabled(fieldName: PricingFieldName): boolean;
}

export default function BorrowersStep({ isFieldEnabled }: BorrowerStepProps) {
    const { fields, append, remove } = useFieldArray<ManualLoanEntryFormValues>({ name: borrowerArrayName });

    const disableAdd = fields.length >= 4;

    return (
        <ManualLoanEntryStep
            className={styles.root}
            secondaryButton={(
                <Button
                    onClick={() => append({})}
                    disabled={disableAdd}
                    tooltip={disableAdd ? 'Only 4 borrowers are allowed per loan' : ''}
                >
                    Add borrower
                </Button>
            )}
        >
            {fields.map((field, index) => (
                <Paper
                    key={field.id}
                    variant="outlined"
                    className={styles.borrowerContainer}
                >
                    <FormLabel className={styles.label}>
                        Borrower {index + 1}
                    </FormLabel>

                    <TextField<ManualLoanEntryFormValues>
                        name={`${borrowerArrayName}.${index}.firstName`}
                        label="First name"
                        size="small"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name={`${borrowerArrayName}.${index}.middleName`}
                        label="Middle name"
                        size="small"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name={`${borrowerArrayName}.${index}.lastName`}
                        label="Last name"
                        size="small"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name={`${borrowerArrayName}.${index}.fico`}
                        label="FICO score"
                        size="small"
                        type="number"
                        rules={{
                            ...isAtLeast(300),
                            ...isAtMost(850)
                        }}
                        required={isFieldEnabled(PricingFieldName.FICO_CREDIT_SCORE)}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name={`${borrowerArrayName}.${index}.email`}
                        label="Email address"
                        size="small"
                        maxLength={100}
                        rules={isEmail}
                    />

                    <div className={styles.switchContainer}>
                        <Switch<ManualLoanEntryFormValues>
                            name={`${borrowerArrayName}.${index}.primaryWageEarner`}
                            label="Primary wage earner"
                        />

                        <Switch<ManualLoanEntryFormValues>
                            name={`${borrowerArrayName}.${index}.firstTimeHomeBuyer`}
                            label="First time home buyer"
                            required={isFieldEnabled(PricingFieldName.FIRST_TIME_HOME_BUYER)}
                        />
                    </div>

                    <MuiButton
                        className={styles.removeButton}
                        disabled={fields.length === 1}
                        onClick={() => remove(index)}
                    >
                        Remove borrower
                    </MuiButton>
                </Paper>
            ))}
        </ManualLoanEntryStep>
    );
}
