import api, {
    LoanPricingResult, LoanPricingResultDetail, PaginatedGetParams, PricingResultProduct, RateSheet
} from '@api';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    createDate, getRandomEnumValue, getRandomItemFromArray, getRandomNumberEnumValue, randomBoolean, randomNum
} from '@tsp-ui/core/utils';
import { rest } from 'msw';

import {
    AmortizationType, CommitmentType, LockPeriod, ProductType, amortizationTypeDisplay, productTypeDisplay
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/due-diligence-step/due-diligence-step-mocks';

/**
 * This array corresponds to the ids in customerSummaryResults in customer-mocks.ts.
 * We cannot import it here because it would create a circular dependency
 * */
const customerIds = [ ...Array(11) ].map((_, i) => (i + 1).toString());

let pricingResultId = 0;
export const mocks = [
    rest.get(getMockUrl('/client/:clientId/pricing/rate-sheet'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(ratesheets)
    ))),
    rest.get(getMockUrl('/client/:clientId/pricing/rate-sheet/current'), (req, res, ctx) => {
        const currentRateSheet = ratesheets.find(sheet => sheet.isCurrent);
        return res(
            ctx.status(200),
            ctx.json(currentRateSheet || {})
        );
    }),
    rest.get(getMockUrl('/client/:clientId/pricing/rate-sheet/:rateSheetId'), (req, res, ctx) => {
        const { rateSheetId } = req.params;
        const rateSheet = ratesheets.find(sheet => sheet.id === rateSheetId);
        return res(
            ctx.status(200),
            ctx.json(rateSheet || {})
        );
    }),
    rest.post(getMockUrl('/client/:clientId/pricing/rate-sheet'), (req, res, ctx) => {
        const newRateSheet = {
            id: `newRateSheet${ratesheets.length + 1}`,
            creatorUserId: 'newUser',
            effectiveDate: new Date().toISOString(),
            expirationDate: null,
            isCurrent: false,
            excelUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
            pdfUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
        };
        ratesheets.push(newRateSheet);
        return res(
            ctx.status(201),
            ctx.json(newRateSheet)
        );
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing/rate-sheet'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(ratesheets)
    ))),
    rest.get(getMockUrl('/client/:clientId/pricing'), (req, res, ctx) => {
        const params = req.url.searchParams;
        const queryParams: PaginatedGetParams = {
            pageNumber: parseInt(params.get('page') || '1'),
            pageSize: parseInt(params.get('pageSize') || '10')
        };

        const paginatedResults = paginatePricingResults(
            loanPricingResults, queryParams.pageNumber, queryParams.pageSize || 0
        );

        return res(
            ctx.status(200),
            ctx.delay(randomNum(500, 1000)),
            ctx.json(paginatedResults)
        );
    }),
    // TODO move to loans
    rest.get(getMockUrl('/client/:clientId/pricing/floated'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(floatedLoans)
    ))),
    rest.get(getMockUrl('/client/:clientId/pricing/:pricingResultID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(getLoanPricingResultDetail())
    )),
    rest.post<{ loanID: string }>(getMockUrl('/client/:clientId/pricing/reprice'), (req, res, ctx) => {
        const { loanID } = req.body;

        const loanToReprice = loanPricingResults.find(loan => loan.loanId === loanID)
          || floatedLoans.find(loan => loan.loanId === loanID);

        if (!loanToReprice) {
            return res(ctx.status(404), ctx.json({ error: 'Loan not found' }));
        }

        const repricedLoan: LoanPricingResult = {
            ...loanToReprice,
            pricedDate: new Date().toISOString(),
            expirationDate: createDate(365).toISOString(),
            interestRate: randomNum(4, 8, 2)
        };

        setTimeout(() => {
            api.webSocket.simulateRepricingComplete(repricedLoan);
        }, 1000);

        return res(
            ctx.status(200),
            ctx.delay(randomNum(500, 1000)),
            ctx.json(repricedLoan)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/pricing/price'), (req, res, ctx) => {
        api.webSocket.simulatePricingComplete();

        return (res(
            ctx.status(200),
            ctx.json(loanPricingResults[0])
        ));
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(loanPricingResults)
    ))),
    // TODO move to loans
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing/floated'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(floatedLoans)
    ))),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing/:pricingResultID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(randomNum(500, 1000)),
        ctx.json(getLoanPricingResultDetail())
    )),
    rest.post<{ loanID: string }>(getMockUrl('/client/:clientId/customer/:customerId/pricing/reprice'), (req, res, ctx) => {
        const { loanID } = req.body;

        const loanToReprice = loanPricingResults.find(loan => loan.loanId === loanID)
          || floatedLoans.find(loan => loan.loanId === loanID);

        if (!loanToReprice) {
            return res(ctx.status(404), ctx.json({ error: 'Loan not found' }));
        }

        const repricedLoan: LoanPricingResult = {
            ...loanToReprice,
            pricedDate: new Date().toISOString(),
            expirationDate: createDate(365).toISOString(),
            interestRate: randomNum(4, 8, 2)
        };

        setTimeout(() => {
            api.webSocket.simulateRepricingComplete(repricedLoan);
        }, 1000);

        return res(
            ctx.status(200),
            ctx.delay(randomNum(500, 1000)),
            ctx.json(repricedLoan)
        );
    }),
    rest.post(getMockUrl('/client/:clientId/customer/:customerId/pricing/price'), (req, res, ctx) => {
        api.webSocket.simulatePricingComplete();

        return (res(
            ctx.status(200),
            ctx.json(loanPricingResults[0])
        ));
    }),
    rest.get(getMockUrl('/client/:clientId/pricing/ratesheet/current/rates'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(getCurrentNoteRates())
    )),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing/ratesheet/current/rates'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.json(getCurrentNoteRates())
    )),
    rest.get(getMockUrl('/client/:clientId/pricing/:loanId/price'), (req, res, ctx) => {
        const pricingDetail = getLoanPricingResultDetail();
        const productsWithVariants = pricingDetail.products
            .flatMap(baseProduct => generateProductVariants(baseProduct));

        const uniqueProducts = productsWithVariants
            .filter((product, index, self) => index === self.findIndex(p => p.noteRate === product.noteRate));

        return res(
            ctx.status(200),
            ctx.json({
                products: uniqueProducts
            })
        );
    }),
    rest.get(getMockUrl('/client/:clientId/customer/:customerId/pricing/:loanId/price'), (req, res, ctx) => {
        const pricingDetail = getLoanPricingResultDetail();
        const productsWithVariants = pricingDetail.products
            .flatMap(baseProduct => generateProductVariants(baseProduct));

        const uniqueProducts = productsWithVariants
            .filter((product, index, self) => index === self.findIndex(p => p.noteRate === product.noteRate));

        return res(
            ctx.status(200),
            ctx.json({
                products: uniqueProducts
            })
        );
    })
];

let loanID = 1;
let loanNumber = 7543670;

const loanPricingResults: LoanPricingResult[] = [
    getMockedPricingResult('Jim Burrows'),
    getMockedPricingResult('John Burrows'),
    getMockedPricingResult('Jeff Burrows', true)
].reverse();

const floatedLoans: LoanPricingResult[] = [ getMockedPricingResult('Jane Burrows') ];

export function getMockedPricingResult(borrowerName: string, isExpired?: boolean): LoanPricingResult {
    return {
        id: `${pricingResultId++}`,
        loanId: String(loanID++),
        loanNumber: String(loanNumber++),
        loanAmount: randomNum(100, 175) * 1000,
        interestRate: randomNum(4, 8, 2),
        borrowerName,
        rateSheetId: '01MAY2024-B',
        pricedDate: '2021-12-12T05:00:00.000Z',
        expirationDate: createDate(isExpired ? -10 : 365).toISOString(),
        customerId: getRandomItemFromArray(customerIds)
    };
}

export const loanTerms = [
    10, 15, 20, 30
];

const GSEs = [ 'Fannie Mae', 'Freddie Mac' ];
const sampleAdjustmentNames = [
    'Loan amount is > $30,000 AND State is OK',
    'FICO is >= 740 AND LTV is between 80% - 85%',
    'Loan amt is $200,001 - Limit',
    'Cap adjustment'
];

export const generateSampleProductName = (productType?: ProductType, amorType?: AmortizationType, term?: number) => [
    amortizationTypeDisplay[amorType || getRandomEnumValue(AmortizationType)],
    `${term || getRandomItemFromArray(loanTerms)}Yr`,
    getRandomItemFromArray(GSEs),
    productTypeDisplay[productType || getRandomEnumValue(ProductType)]
].join(' ');

let productID = 100;
const getLoanPricingResultDetail = (): LoanPricingResultDetail => ({
    defaultLockPeriod: LockPeriod.SEVEN,
    products: [ ...Array(randomNum(20, 30)) ].map(() => ({
        productId: `${productID++}`,
        basePrice: randomNum(90, 110, 2),
        commitmentType: randomBoolean() ? CommitmentType.MANDATORY : CommitmentType.BEST_EFFORT,
        description: generateSampleProductName(),
        docUrl: SAMPLE_PDF_URL,
        finalPrice: randomNum(90, 110, 2),
        investor: 'Agency',
        llpas: randomNum(-.5, .5, 3),
        lockPeriod: getRandomNumberEnumValue(LockPeriod),
        margin: randomNum(-.5, .5, 3),
        adjustments: Math.random() < 0.8 ? sampleAdjustmentNames.map((description, index) => ({
            id: index.toString(),
            description,
            amount: randomNum(-.5, .5, 3),
            srp: randomBoolean(),
            gos: randomBoolean(),
            isCap: randomBoolean(),
            capTotal: randomNum(-.5, .5, 3)
        })) : []
    })),
    ineligibleProducts: [
        {
            productId: '1',
            description: generateSampleProductName(),
            ineligibleReason: 'Federal housing loans not permitted above 20Yr term'
        },
        {
            productId: '1',
            description: generateSampleProductName(),
            ineligibleReason: 'Fixed required'
        }
    ]
});

const ratesheets: RateSheet[] = [
    {
        id: '01MAY2024-B',
        creatorUserId: 'c3d5a064-91a7-4d52-8b87-9c52c7a04f3e',
        effectiveDate: '2024-05-02T09:00:00.000',
        expirationDate: null,
        isCurrent: true,
        excelUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
        pdfUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        id: '01MAY2024-A',
        creatorUserId: '9a726b29-0f94-4d8c-b9cc-671dd6323b15',
        effectiveDate: '2024-05-01T09:00:00.000',
        expirationDate: '2024-05-02T14:00:00.000',
        isCurrent: false,
        excelUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
        pdfUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        id: '16FEB2024-A',
        creatorUserId: 'b14d9532-0711-4f57-aece-f37f1b44e7d7',
        effectiveDate: '2024-02-16T09:00:00.000',
        expirationDate: '2024-02-27T17:00:00.000',
        isCurrent: false,
        excelUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    },
    {
        id: '01FEB2024-A',
        creatorUserId: 'e67c1c0b-2f88-42e4-8d9f-85f4548e7b98',
        effectiveDate: '2024-02-01T09:00:00.000',
        expirationDate: '2024-02-15T17:00:00.000',
        isCurrent: false,
        excelUrl: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
    }
];

function paginatePricingResults(
    results: LoanPricingResult[], page: number, pageSize: number
): PaginatedResponse<LoanPricingResult> {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedItems = results.slice(startIndex, endIndex);

    return {
        data: paginatedItems,
        pageNumber: page,
        pageSize,
        totalRecords: results.length,
        totalPages: Math.ceil(results.length / pageSize)
    };
}

function getCurrentNoteRates() {
    const baseRates = [
        3.523, 4.271, 5.349
    ];

    const loanRates = loanPricingResults.map(loan => loan.interestRate);

    const allRates = [ ...baseRates, ...loanRates ];
    const uniqueRates = Array.from(new Set(allRates)).sort((a, b) => a - b);

    return uniqueRates;
}

function generateProductVariants(baseProduct: PricingResultProduct): PricingResultProduct[] {
    return getCurrentNoteRates().map(noteRate => ({
        ...baseProduct,
        productId: `${baseProduct.productId}-${baseProduct.noteRate || 0}`,
        noteRate,
        basePrice: randomNum(90, 110, 2),
        finalPrice: randomNum(90, 110, 2),
        margin: randomNum(-0.5, 0.5, 3),
        llpas: randomNum(-0.5, 0.5, 3)
    }));
}
