import api, {
    LOSLoanStatus, LoanActivity, LoanAlert, LoanStatusConfig,
    LoanSummary,
    PermissionType, RegisteredLoan,
    UserType
} from '@api';
import {
    Badge, Pagination, Skeleton, TextField, Typography
} from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    Button,
    ExpandableHeader, RoutedDialogManager,
    usePageMessage
} from '@tsp-ui/core/components';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHandlePromiseSettledResult, withAuth } from '@utils';
import ViewportFill from '@views/components/ViewportFill';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import RegisteredLoanCard from '@views/loans/components/RegisteredLoanCard';
import { useGetLockDetails } from '@views/product-pricing/ProductAndPricingPage';
import {
    ReactNode, createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useDebounce } from 'use-debounce';

import Page from '../components/Page';

import styles from './LoansPage.module.scss';
import { CustomAlerts } from './components/CustomAlerts';
import CustomerAutocomplete from './components/CustomerAutoComplete';
import LoanDataDialog from './components/LoanDataDialog';
import LoanNumberAutoComplete from './components/LoanNumberAutoComplete';
import { RecentLoans } from './components/RecentLoans';


const loansPerPage = 20;

const { CLIENT } = UserType;

export const LoansContext = createContext<{
    loanStatusConfigs: LoanStatusConfig[];
    losLoanStatuses: LOSLoanStatus[];
    isLockDeskOpen?: boolean;
        }>({
            loanStatusConfigs: [],
            losLoanStatuses: [],
            isLockDeskOpen: true
        });

/**
 * Renders the loan pipeline page
 *
 * @constructor
 */
export default function LoansPage() {
    const [ loanSummaries, setLoanSummaries ] = useState<LoanSummary[]>([]);
    const [ loanStatusConfigs, setLoanStatusConfigs ] = useState<LoanStatusConfig[]>([]);
    const [ losLoanStatuses, setLosLoanStatuses ] = useState<LOSLoanStatus[]>([]);
    const [ loanAlerts ] = useState<LoanAlert[]>([]);
    const [ recentLoans ] = useState<LoanActivity[]>([]);
    const [ loading, setLoading ] = useState(true);
    const { id: clientId, customerId, accountUserType } = useGetCurrentAccount();

    const [ loanNumberSearch, setLoanNumberSearch ] = useState<string | null>('');
    const [ borrowerNameSearch, setBorrowerNameSearch ] = useState<string | null>('');
    const [ customerIdSearch, setCustomerIdSearch ] = useState('');

    const fetchFilteredLoans = useCallback(async () => {
        try {
            const summaries = await api.loans.getLoansSummary(clientId, customerId, {
                loanNumber: loanNumberSearch!.length > 2 ? loanNumberSearch : null,
                borrowerName: borrowerNameSearch!.length > 2 ? borrowerNameSearch : null,
                customerId: customerIdSearch
            });
            setLoanSummaries(summaries);
        } catch (error) {
            console.error('Error fetching filtered loans:', error);
        }
    }, [
        clientId, customerId, loanNumberSearch, borrowerNameSearch, customerIdSearch
    ]);

    const [ debouncedFetchFilteredLoans ] = useDebounce(fetchFilteredLoans, 300);

    useEffect(() => {
        debouncedFetchFilteredLoans();
    }, [ debouncedFetchFilteredLoans ]);

    const [ showFilters, setShowFilters ] = useState(false);

    const activeFilterCount = useMemo(() => [
        loanNumberSearch, borrowerNameSearch, customerIdSearch
    ].filter(Boolean).length, [
        loanNumberSearch, borrowerNameSearch, customerIdSearch
    ]);

    function handleClearFilters() {
        setLoanNumberSearch('');
        setBorrowerNameSearch('');
        setCustomerIdSearch('');
    }

    const handlePromiseSettledResult = useHandlePromiseSettledResult();

    useAsyncEffect(useCallback(async () => {
        const [
            loanStatusConfigsResult, losLoanStatusesResult
            /* loanAlertsResult, recentLoansResult*/
        ] = await Promise.allSettled([
            api.loanStatus.getLoanStatusConfigs(clientId),
            api.loanStatus.getLOSLoanStatuses(clientId)
            // api.loans.getLoanAlerts(), TODO uncomment when loan alerts is implemented
            // api.loans.getRecentLoans()
        ]);

        handlePromiseSettledResult(loanStatusConfigsResult, setLoanStatusConfigs, 'An error occurred while fetching loan status configs');
        handlePromiseSettledResult(losLoanStatusesResult, setLosLoanStatuses, 'An error occurred while fetching LOS loan statuses');
        // handlePromiseSettledResult(loanAlertsResult, setLoanAlerts, 'An error occurred while fetching loan alerts');
        // handlePromiseSettledResult(recentLoansResult, setRecentLoans,
        // 'An error occurred while fetching recent loans');

        setLoading(false);
    }, [ clientId, handlePromiseSettledResult ]));

    const orderedConfigs = loanStatusConfigs.sort((a, b) => a.displayOrder - b.displayOrder);

    const otherLoansCount = loanSummaries.find(s => s.statusConfigId === null)?.loanCount;

    const { isLockDeskOpen, lockDetailsLoading } = useGetLockDetails();

    const loansContextValue = {
        loanStatusConfigs: loanStatusConfigs || [],
        losLoanStatuses: losLoanStatuses || [],
        isLockDeskOpen
    };

    return (
        <LoansContext.Provider value={loansContextValue}>
            <Page
                headerActions={(
                    <Badge
                        badgeContent={activeFilterCount}
                        color="primary"
                        invisible={activeFilterCount === 0}
                    >
                        <Button
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {`${showFilters ? 'Hide' : 'Show'} filters`}
                        </Button>

                        {activeFilterCount > 0 && (
                            <Button
                                onClick={handleClearFilters}
                            >
                                Clear filters
                            </Button>
                        )}
                    </Badge>
                )}
                header={(
                    <div className={styles.header}>
                        <span>Loan Pipeline</span>

                        {showFilters && (
                            <div className={styles.filterContainer}>
                                <LoanNumberAutoComplete
                                    className={styles.filterField}
                                    loanNumberSearch={loanNumberSearch}
                                    setLoanNumberSearch={setLoanNumberSearch}
                                />

                                <TextField
                                    className={styles.filterField}
                                    label="Filter by borrower name"
                                    value={borrowerNameSearch}
                                    onChange={(event) => setBorrowerNameSearch(event.target.value)}
                                    variant="filled"
                                    size="small"
                                />

                                {accountUserType === CLIENT && (
                                    <CustomerAutocomplete
                                        className={styles.filterField}
                                        customerIdSearch={customerIdSearch}
                                        setCustomerIdSearch={setCustomerIdSearch}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
                loading={loading || lockDetailsLoading}
                className={styles.main}
            >
                <ViewportFill
                    className={styles.root}
                >
                    <div className={styles.sections}>
                        {orderedConfigs.map(loanStatusGroup => {
                            const summary = loanSummaries.find(s => s.statusConfigId === loanStatusGroup.id);
                            return (
                                <LoansSection
                                    key={loanStatusGroup.id}
                                    title={loanStatusGroup.title}
                                    statusConfigId={loanStatusGroup.id}
                                    loanCount={summary?.loanCount || 0}
                                    loanNumberSearch={loanNumberSearch}
                                    borrowerNameSearch={borrowerNameSearch}
                                    customerIdSearch={customerIdSearch}
                                />
                            );
                        })}

                        {otherLoansCount && otherLoansCount > 0 && (
                            <LoansSection
                                title="Other Loans"
                                statusConfigId={null}
                                loanCount={otherLoansCount || 0}
                                loanNumberSearch={loanNumberSearch}
                                borrowerNameSearch={borrowerNameSearch}
                                customerIdSearch={customerIdSearch}
                            />
                        )}
                    </div>

                    <div className={styles.sidebar}>
                        <CustomAlerts loanAlerts={loanAlerts} />

                        <RecentLoans recentLoans={recentLoans} />
                    </div>
                </ViewportFill>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </LoansContext.Provider>
    );
}

const dialogRoutes = {
    ':loanID/loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    ':loanID/loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true)
};

interface LoansSectionProps {
    title: ReactNode;
    statusConfigId: number | null;
    loanCount: number | undefined;
    loanNumberSearch: string | null;
    borrowerNameSearch: string | null;
    customerIdSearch: string | null;
}

function LoansSection({
    title, statusConfigId, loanCount, loanNumberSearch,
    borrowerNameSearch, customerIdSearch
}: LoansSectionProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ loans, setLoans ] = useState<PaginatedResponse<RegisteredLoan>>({
        data: [],
        totalPages: 0,
        totalRecords: 0,
        pageNumber: 1,
        pageSize: loansPerPage
    });
    const [ loading, setLoading ] = useState(true);
    const [ isExpanded, setIsExpanded ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        if (!isExpanded) {
            return;
        }
        setLoading(true);
        try {
            const fetchedLoans = await api.loans.getRegisteredLoans(clientId, customerId, {
                loanNumber: loanNumberSearch!.length > 2 ? loanNumberSearch : null,
                borrowerName: borrowerNameSearch!.length > 2 ? borrowerNameSearch : null,
                customerId: customerIdSearch,
                statusConfigId,
                pageSize: loansPerPage,
                pageNumber
            });
            setLoans(fetchedLoans);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loans', error);
        } finally {
            setLoading(false);
        }
    }, [
        borrowerNameSearch, clientId, customerId, customerIdSearch, isExpanded,
        loanNumberSearch, pageMessage, pageNumber, statusConfigId
    ]));

    const [ sectionHeight, setSectionHeight ] = useState('auto');
    const loanCardsRef = useRef<(HTMLDivElement | null)[]>([]);
    const loansData = loans.data;

    useLayoutEffect(() => {
        if (isExpanded && loanCardsRef.current.length > 0) {
            const calculateHeight = () => {
                const visibleCards = loanCardsRef.current.slice(0, 3);
                const cardHeights = visibleCards.map(card => card?.offsetHeight || 0);
                const totalHeight = cardHeights.reduce(
                    (sum, height) => sum + height, 0
                ) + (12 * visibleCards.length);

                return `${totalHeight + 42}px`; // Apply total height + padding/margin
            };

            setSectionHeight(calculateHeight());
        } else {
            setSectionHeight('42px');
        }
    }, [ isExpanded, loansData ]);

    const skeletonNumber = Math.min(loanCount || 0, loansPerPage);

    return (
        <div
            className={styles.section}
            style={{ minHeight: sectionHeight }}
        >
            <ExpandableHeader
                disableExpand={!loanCount}
                defaultExpand={false}
                onExpandToggle={setIsExpanded}
                title={title}
                secondaryText={loanCount === undefined ? (
                    <Skeleton
                        width="60px"
                        height={30}
                    />
                ) : `${loanCount} loan${loanCount !== 1 ? 's' : ''}`}
                expandedContent={(
                    <div className={styles.expandedContent}>
                        {loading ? (
                            <div className={styles.skeletonContainer}>
                                {Array(skeletonNumber).fill(true).map((_) => (
                                    <Skeleton
                                        variant="rounded"
                                        width="100%"
                                        height={60}
                                    />
                                ))}
                            </div>
                        ) : customerIdSearch && !loans.data.length ? (
                            <Typography className={styles.noLoansText}>
                                This customer currently has no loans in this status
                            </Typography>
                        ) : (
                            <TransitionGroup className={styles.loans}>
                                {loans.data.map((loan, index) => (
                                    <CSSTransition
                                        key={loan.id}
                                        exit={false}
                                        timeout={250 + (index * 50)}
                                        classNames={{
                                            enter: styles.pricingResultCardTransition_enter,
                                            enterActive: styles.pricingResultCardTransition_enter_active
                                        }}
                                    >
                                        <div ref={el => loanCardsRef.current[index] = el}>
                                            <RegisteredLoanCard
                                                loan={loan}
                                                setLoans={setLoans}
                                            />
                                        </div>
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        )}
                    </div>
                )}
            />

            {isExpanded && loans.totalPages > 1 && (
                <Pagination
                    count={loans.totalPages}
                    page={pageNumber}
                    onChange={(_, page) => setPageNumber(page)}
                    className={styles.pagination}
                />
            )}
        </div>
    );
}
