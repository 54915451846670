import api, {
    LoanDocument, LoanEvent, LoanPricingResult,
    LoanRegistrationParams,
    PermissionType, RegisteredLoan, RegistrationType
} from '@api';
import {
    CheckCircleOutline, Description, Edit, Lock
} from '@mui/icons-material';
import {
    Alert, Button, Grow, LinearProgress, MenuItem, Popover, Tooltip, Typography
} from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    FileInput, FilledSection, IconButton, IconTypography, LabeledValue, ProgressIndicator
} from '@tsp-ui/core/components';
import {
    isPastDate, replaceItemById, useAsyncEffect, usePageMessage
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import { LoanPricingResultDetails } from '@views/product-pricing/components/LoanPricingResultCard';
import PricingExpiredButton from '@views/product-pricing/components/PricingExpiredButton';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useContext, useEffect, useState
} from 'react';
import { useDebounce } from 'use-debounce';

import { LoansContext } from '../LoansPage';

import { LoanCard } from './LoanCard';
import { LoanTimeline } from './LoanTimeline';
import styles from './RegisteredLoanCard.module.scss';


interface RegisteredLoanCardProps {
    loan: RegisteredLoan;
    loanPricingResults?: PaginatedResponse<LoanPricingResult>;
    updateLoanPricingResults?: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    updateFloatedLoans?: () => void;
    showPricingResults?: boolean;
    setLoans?: Dispatch<SetStateAction<PaginatedResponse<RegisteredLoan>>>;
    isProductAndPricingPage?: boolean;
    currentNoteRates?: number[];
}

export default function RegisteredLoanCard({
    loan, loanPricingResults, setLoans, updateLoanPricingResults,
    updateFloatedLoans, showPricingResults, isProductAndPricingPage, currentNoteRates
}: RegisteredLoanCardProps) {
    const {
        loanStatus, expirationDate, id: loanId, pricingResultId, interestRate
    } = loan;
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ loanDocuments, setLoanDocuments ] = useState<LoanDocument[]>();
    const pageMessage = usePageMessage();
    const { isLockDeskOpen } = useContext(LoansContext);
    const [ pricingResult, setPricingResult ] = useState<LoanPricingResult | null>(null);

    useAsyncEffect(useCallback(async () => { // get loan data for card
        try {
            setLoanDocuments(
                (await api.loans.document.getLoanDocuments(
                    clientId, loan.id, customerId
                )).filter(({ instanceCount }) => instanceCount === 1)
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }

        setDocsLoading(false);
    }, [
        clientId, customerId, loan.id, pageMessage
    ]));

    const loanPricingResultsData = loanPricingResults?.data;

    useEffect(() => {
        if (!pricingResult && showPricingResults) {
            const result = loanPricingResultsData?.find(r => r.loanId === pricingResultId);
            if (result) {
                setPricingResult(result);
            }
        }
    }, [
        pricingResult, loanId, pricingResultId, loanPricingResultsData, showPricingResults
    ]);

    // const [ eventsLoading, setEventsLoading ] = useState(true);
    const [ docsLoading, setDocsLoading ] = useState(true);

    const [ loanEvents, setLoanEvents ] = useState<LoanEvent[]>();
    const [ showFetchError ] = useState(false);

    // TODO loan events story
    // useAsyncEffect(useCallback(async () => { // get load data for expanded content
    //     try {
    //         const loanEvents = await api.loans.getLoanEvents(id);
    //         setLoanEvents(loanEvents);
    //     } catch (error) {
    //         setShowFetchError(true);
    //     }

    //     setEventsLoading(false);
    // }, [ id ]));


    const [ selectedNoteRate, setSelectedNoteRate ] = useState<number | null>(null);

    useEffect(() => {
        if (interestRate !== undefined && selectedNoteRate === null) {
            setSelectedNoteRate(interestRate);
        }
    }, [ interestRate, selectedNoteRate ]);

    return (
        <LoanCard
            className={styles.root}
            // loading={eventsLoading}
            loan={loan}
            isPendingLoan={false}
            isProductAndPricingPage={isProductAndPricingPage}
            currentNoteRates={currentNoteRates}
            selectedNoteRate={selectedNoteRate}
            setSelectedNoteRate={setSelectedNoteRate}
            expandedContent={showFetchError ? (
                <Alert // TODO post-demo maybe show sub-messages as well
                    severity="error"
                    className={styles.alert}
                >
                    An error occurred while fetching the loan information
                </Alert>
            ) : (
                showPricingResults ? (
                    <LoanPricingResultDetails
                        loanId={loanId}
                        pricingResultId={pricingResult?.id || ''}
                        updateLoanPricingResults={updateLoanPricingResults}
                        updateFloatedLoans={updateFloatedLoans}
                        isExpired={new Date(pricingResult?.expirationDate || '') < new Date()}
                        interestRate={interestRate || 0}
                        selectedNoteRate={selectedNoteRate}
                    />
                ) : (
                    <LoanCardContent
                        loan={loan}
                        setLoans={setLoans}
                        loanEvents={loanEvents || []}
                        setLoanEvents={setLoanEvents}
                        loanDocuments={loanDocuments}
                        setLoanDocuments={setLoanDocuments}
                    />
                )
            )}
            additionalDetails={(
                <div className={styles.additionalDetails}>
                    {loan.registrationType === RegistrationType.FLOAT && (
                        <IconButton
                            disabled={!isLockDeskOpen}
                            onClick={async () => {
                                try {
                                    /**
                                     * TODO: We need to add loanPricingProductId and pricingResultId to the Loan table
                                     * to link floated loans to their respective product and pricing result. Then we can
                                     * update the RegisteredLoan interface to include these fields.
                                     */
                                    await api.loans.registerLoan(clientId, loan.id, {
                                        registrationType: RegistrationType.LOCK,
                                        productId: (loan as RegisteredLoan & { productId: string }).productId,
                                        pricingResultId: (loan as RegisteredLoan & { pricingResultId: string }).pricingResultId || '1'
                                    } as LoanRegistrationParams, customerId);
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while locking the loan', error);
                                }
                            }}
                            tooltip={isLockDeskOpen ? 'Lock loan' : 'Cannot lock loan while the lock desk is closed'}
                        >
                            <Lock
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    )}

                    {docsLoading ? (<ProgressIndicator className={styles.docsLoading} />) : (
                        <IconTypography
                            className={styles.iconTypography}
                            icon={(
                                <Tooltip title={`${loanDocuments?.length || 'No'} document${loanDocuments?.length === 1 ? '' : 's'} indexed`}>
                                    <Description
                                        color="primary"
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        >
                            {loanDocuments?.length}
                        </IconTypography>
                    )}

                    <div className={styles.additionalContent}>
                        {isPastDate(expirationDate) && (
                            <PricingExpiredButton
                                expirationDate={expirationDate || 'N/A'}
                                loanId={loanId}
                                updateLoanPricingResults={updateLoanPricingResults}
                                updateFloatedLoans={updateFloatedLoans}
                            />
                        )}

                        <div>
                            <Typography
                                variant="caption"
                                align="right"
                                component="div"
                            >
                                {
                                    /* TODO post-demo hard coded for now in lieu of fetching a real
                                    status update after a document upload */
                                    loanStatus
                                }
                            </Typography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                component="div"
                                align="right"
                            >
                                {isPastDate(expirationDate) ? 'Lock expired' : `Lock expires in ${expirationDate ? formatDistanceToNowStrict(parseISO(expirationDate)) : 'N/A'}`}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
        />
    );
}

interface LoanCardContentProps {
    loan: RegisteredLoan;
    setLoans: Dispatch<SetStateAction<PaginatedResponse<RegisteredLoan>>> | undefined;
    loanDocuments?: LoanDocument[];
    setLoanDocuments: Dispatch<SetStateAction<LoanDocument[] | undefined>>;
    loanEvents: LoanEvent[];
    setLoanEvents: Dispatch<SetStateAction<LoanEvent[] | undefined>>;
}

function LoanCardContent({
    loan, setLoans, setLoanDocuments, loanEvents, setLoanEvents, loanDocuments = []
}: LoanCardContentProps) {
    const { losLoanStatuses, loanStatusConfigs } = useContext(LoansContext);
    const loanStatusId = losLoanStatuses.find(status => status.name === loan.loanStatus)?.id;
    const loanStatusConfig = loanStatusConfigs.find(config => config.losLoanStatusIds.includes(loanStatusId!));
    const docsRequired = loanStatusConfig?.displayOrder === 1;
    const { id } = loan;
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    // TODO post-demo implement the actual workflow for uploading additional docs
    const [ addAnotherDoc, setAddAnotherDoc ] = useState(false);

    const [ fileAdded, setFileAdded ] = useState(false);
    const [ indexingComplete, setIndexingComplete ] = useState(false);
    const [ debouncedIndexingComplete ] = useDebounce(indexingComplete, 1000);
    const fileLoading = fileAdded && !indexingComplete;

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement>();

    const pageMessage = usePageMessage();

    async function handleUpload(files: File[]) {
        setFileAdded(true);

        const formData = new FormData();
        formData.append('file', files[0]);

        try {
            await api.loans.document.uploadLoanDocument(clientId, loan.id, formData, customerId);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading a loan document', error);
        }

        setIndexingComplete(true);
    }

    async function onUploadComplete() {
        setLoanDocuments(await api.loans.document.getLoanDocuments(clientId, loan.id, customerId));
        setLoanEvents(await api.loans.getLoanEvents(clientId, loan.id, customerId));
        setIndexingComplete(true);

        setLoans?.(await api.loans.getRegisteredLoans(clientId, customerId));
    }

    const handleStatusUpdate = async (statusId: string) => {
        setLoading(true);

        try {
            const updatedLoan = await api.loans.updateLoanStatus(clientId, id, statusId);
            setLoans?.((loans) => ({
                ...loans,
                data: replaceItemById(loans.data, updatedLoan)
            }));

            pageMessage.success('Loan status updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the loan status', error);
        }

        setAnchorEl(undefined);
        setLoading(false);
    };

    const [ canEditLoanStatus ] = useHasPermission([ PermissionType.EDIT_LOAN_STATUS ]);

    return docsRequired ? (
        <div className={styles.expandableContent}>
            <LoanTimeline
                loanEvents={loanEvents}
                isAwaitingDocs={loanDocuments.length === 0 && !fileAdded}
                isIndexing={fileLoading}
                isInProgress={
                    (fileAdded && debouncedIndexingComplete) || (!fileAdded && loanDocuments.length > 0)
                }
            />

            {!addAnotherDoc && (loanDocuments.length > 0 || indexingComplete) ? (
                <Grow
                    in
                    timeout={1500}
                >
                    <div className={styles.fileUploadContainer}>
                        <CheckCircleOutline
                            fontSize="large"
                            color="success"
                        />

                        <Typography>
                            Initial doc package uploaded
                        </Typography>

                        <Button
                            className={styles.addAnotherButton}
                            onClick={() => setAddAnotherDoc(true)}
                        >
                            Add another document
                        </Button>
                    </div>
                </Grow>
            ) : (
                <div className={styles.fileUploadContainer}>
                    <Typography>
                        {addAnotherDoc ? 'Upload an additional document' : 'Upload the initial doc package for this loan'}
                    </Typography>

                    <div className={styles.fileInputContainer}>
                        <FileInput
                            title="file"
                            acceptedFileTypes={fileTypes}
                            disabled={fileLoading}
                            single // TODO post-demo change this back to accept multiple
                            onAddFiles={newFiles => {
                                if (newFiles.length) {
                                    handleUpload(newFiles);

                                    // TODO post-demo remove mock
                                    // api.webSocket.subscribe('UPLOAD_COMPLETE', onUploadComplete);
                                    setTimeout(onUploadComplete, 7000);
                                }
                            }}
                        />

                        {fileLoading && (
                            <div className={styles.fileUploadLoader}>
                                <LinearProgress />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {loan.registrationType === RegistrationType.FLOAT || RegistrationType.LOCK ? (
                <FilledSection
                    variant="light"
                    className={styles.loanStatusSection}
                >
                    <div>
                        <LabeledValue
                            label="Loan status:"
                            value={loan.loanStatus}
                            variants={{ value: 'body1' }}
                        />
                    </div>

                    <IconButton
                        tooltip={canEditLoanStatus ? 'Update loan status' : 'You do not have permission to edit the loan status'}
                        className={styles.loanStatusButton}
                        disabled={!canEditLoanStatus || loading}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                        <Edit color="secondary" />
                    </IconButton>

                    <Popover
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(undefined)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                    >
                        {losLoanStatuses
                            ?.filter(status => status.name !== loan.loanStatus)
                            .map(status => (
                                <MenuItem
                                    key={status.id}
                                    value={status.id}
                                    onClick={() => handleStatusUpdate(status.id)}
                                >
                                    {status.name}
                                </MenuItem>
                            ))}
                    </Popover>
                </FilledSection>
            ) : null}

        </div>
    ) : null;
}

const fileTypes = [ 'pdf' ];
