import { PricingFieldName } from '@api';
import { FormLabel } from '@mui/material';
import {
    AddressFieldset,
    CurrencyField, TextField, isAtLeast
} from '@tsp-ui/core/components';
import { useRenderTogglableEnumOptions } from '@utils';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';
import styles from './PropertyStep.module.scss';


interface PropertyStepProps extends Pick<ManualLoanEntryStepProps, 'onPreviousButtonClick'> {
    isFieldEnabled: (fieldName: PricingFieldName) => boolean;
}

export default function PropertyStep({ isFieldEnabled, onPreviousButtonClick }: PropertyStepProps) {
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    return (
        <ManualLoanEntryStep onPreviousButtonClick={onPreviousButtonClick}>
            <div>
                <FormLabel>
                    Property details
                </FormLabel>

                <div className={styles.loanInfoContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="propertyType"
                        label="Property type"
                        select
                        required={isFieldEnabled(PricingFieldName.PROPERTY_TYPE)}
                    >
                        {renderTogglableEnumOptions('propertyType')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="propertyUnits"
                        label="Number of units"
                        select
                        required={isFieldEnabled(PricingFieldName.UNITS)}
                    >
                        {renderTogglableEnumOptions('numUnits')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="loanPurpose"
                        label="Purpose"
                        select
                        required={isFieldEnabled(PricingFieldName.LOAN_PURPOSE)}
                    >
                        {renderTogglableEnumOptions('loanPurpose')}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="occupancyType"
                        label="Occupancy"
                        select
                        required={isFieldEnabled(PricingFieldName.OCCUPANCY_TYPE)}
                    >
                        {renderTogglableEnumOptions('occupancyType')}
                    </TextField>

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="appraisedValue"
                        label="Appraised value"
                        required={isFieldEnabled(PricingFieldName.APPRAISED_VALUE)}
                        rules={isAtLeast(0)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="salesPrice"
                        label="Sales price (optional)"
                        rules={isAtLeast(0)}
                        required={isFieldEnabled(PricingFieldName.SALES_PRICE)}
                    />
                </div>
            </div>

            <AddressFieldset<ManualLoanEntryFormValues>
                required={isFieldEnabled(
                    PricingFieldName.PROP_STATE || PricingFieldName.PROP_COUNTY || PricingFieldName.PROP_ZIP
                )}
                fieldNames={{
                    city: 'propertyCity',
                    state: 'propertyState',
                    zip: 'propertyZipCode',
                    county: 'propertyCounty'
                }}
            />
        </ManualLoanEntryStep>
    );
}
