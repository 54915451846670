import api, { PermissionType, PricingResultProduct, RegistrationType } from '@api';
import {
    AddTask, Lock, Tune
} from '@mui/icons-material';
import {
    Link as MuiLink, Typography
} from '@mui/material';
import {
    ExpandableCardTableRow, IconButton,
    useConfirm,
    usePageMessage
} from '@tsp-ui/core/components';
import { tooltipTitle, useGetCurrentAccount, useHasPermission } from '@utils';
import { LoansContext } from '@views/loans/LoansPage';
import { useContext, useState } from 'react';

import PricingResultProductPopover from './PricingResultProductPopover';
import styles from './PricingResultProductRow.module.scss';
import ProductDetailsTable from './ProductDetailsTable';


interface PricingResultProductRowProps {
    isExpired: boolean;
    onRegister(productID: string, registrationType: RegistrationType, noteRate?: number): Promise<void>;
    loanId: string;
    currentNoteRate: number | undefined;
    product: PricingResultProduct;
    originalRate: number;
}

export default function PricingResultProductRow({
    onRegister, isExpired, loanId, currentNoteRate, originalRate, product: {
        productId, adjustments, basePrice, description, docUrl, finalPrice, investor, llpas, margin, noteRate
    }
}: PricingResultProductRowProps) {
    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ canRegister ] = useHasPermission([ PermissionType.CREATE_LOANS ]);
    const { isLockDeskOpen } = useContext(LoansContext);
    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);
    const [ noteRatePricingResults, setNoteRatePricingResults ] = useState<PricingResultProduct[]>([]);

    async function handleRateClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
        try {
            const pricingDetail = await api.pricing.getNoteRatePricingResults(clientId, loanId, customerId);
            setNoteRatePricingResults(pricingDetail.products);
        } catch (error) {
            pageMessage.handleApiError('Error fetching note rate pricing results:', error);
        }
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    return (
        <ExpandableCardTableRow
            expandedContent={(
                <ProductDetailsTable adjustments={adjustments} />
            )}
        >
            <td width="100%">
                <Typography
                    component={MuiLink}
                    href={docUrl}
                    rel="noreferrer noopener"
                    target="_blank"
                    variant="body2"
                    className={styles.productLink}
                >
                    {description}
                </Typography>
            </td>

            <Typography
                component="td"
                variant="body2"
            >
                {investor}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {margin?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {basePrice?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                color={
                    llpas === 0
                        ? 'textPrimary' // TODO make a component for this
                        : llpas! > 0
                            ? 'green'
                            : 'error'
                }
            >
                {llpas?.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {finalPrice?.toFixed(3)}
            </Typography>

            <td className={styles.buttonsCell}>
                <IconButton
                    tooltip={tooltipTitle({
                        [expiredMessage('to view note rates')]: isExpired,
                        [noPermissionMessage('to view note rates')]: !canRegister,
                        'Explore available note rates': canRegister
                    })}
                    size="small"
                    disabled={isExpired || !canRegister}
                    onClick={handleRateClick}
                >
                    <Tune
                        color={isExpired ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>

                <IconButton
                    tooltip={tooltipTitle({
                        [expiredMessage('lock')]: isExpired,
                        [noPermissionMessage('lock')]: !canRegister,
                        'Cannot lock loan while the lock desk is closed': !isLockDeskOpen,
                        'Lock loan': canRegister
                    })}
                    size="small"
                    disabled={!isLockDeskOpen || isExpired || !canRegister}
                    onClick={async () => {
                        const isRateEqual = originalRate === noteRate;

                        if (isRateEqual || (!isRateEqual && await confirm(
                            `Locking this product will change the note rate on the loan from ${originalRate?.toFixed(3)}% to ${noteRate?.toFixed(3)}%. Are you sure you want to continue?`
                        ))) {
                            onRegister(productId, RegistrationType.LOCK, noteRate);
                        }
                    }}
                >
                    <Lock
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>

                <IconButton
                    tooltip={tooltipTitle({
                        [expiredMessage('float')]: isExpired,
                        [noPermissionMessage('float')]: !canRegister,
                        'Float loan': canRegister
                    })}
                    size="small"
                    disabled={isExpired || !canRegister}
                    onClick={async () => {
                        const isRateEqual = originalRate === noteRate;

                        if (isRateEqual || (!isRateEqual && await confirm(
                            `Floating this product will change the note rate on the loan from ${originalRate?.toFixed(3)}% to ${noteRate?.toFixed(3)}%. Are you sure you want to continue?`
                        ))) {
                            onRegister(productId, RegistrationType.FLOAT, noteRate);
                        }
                    }}
                >
                    <AddTask
                        color={isExpired ? 'disabled' : 'secondary'}
                        fontSize="small"
                    />
                </IconButton>
            </td>

            <PricingResultProductPopover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                noteRatePricingResults={noteRatePricingResults}
                description={description}
                currentNoteRate={currentNoteRate}
                isExpired={isExpired}
                canRegister={canRegister}
                isLockDeskOpen={isLockDeskOpen}
                onRegister={onRegister}
            />
        </ExpandableCardTableRow>
    );
}

const expiredMessage = (action: string) => `Pricing expired. Reprice the loan to ${action}`;
const noPermissionMessage = (action: string) => `You do not have permissions to ${action} loans`;
